import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const NotFoundPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="404: Not found" />
      <div className="flex flex-col items-center my-24 mt-8 space-y-4">
        <h1 className="text-5xl font-semibold text-gray-600">Sorry.</h1>

        <div className="text-center">
          <h2>It looks like something went wrong on our end.</h2>
          <h2>Head back to the homepage.</h2>
        </div>

        <span className="inline-flex rounded-md">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700"
          >
            Go Back
          </Link>
        </span>
      </div>
    </Layout>
  )
}

export default NotFoundPage
